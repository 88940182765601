<template>
  <el-container>
    <el-aside :class="{ isCollapse: isCollapse }">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        @close="handleClose"
        :collapse="isCollapse"
      >
        <el-menu-item index="index">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="articlesManage">
          <i class="el-icon-s-order"></i>
          <span slot="title">文章管理</span>
        </el-menu-item>
        <el-menu-item index="categoryManage">
          <i class="el-icon-s-management"></i>
          <span slot="title">文章分类管理</span>
        </el-menu-item>
        <!-- <el-menu-item index="messagesManage">
          <i class="el-icon-s-comment"></i>
          <span slot="title">留言管理</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="accountManage">
          <i class="el-icon-s-tools"></i>
          <span slot="title">账号管理</span>
        </el-menu-item> -->
      </el-menu>
    </el-aside>
    <el-container class="main">
      <el-header>
        <div class="left">
          <i :class="icon" @click="collapse"></i>
          <span>管理平台</span>
        </div>
        <el-dropdown @command="handleCommand" class="right">
          <span>
            <!-- <el-avatar
              :size="50"
              v-if="myUserInfo.avatar"
              :src="myUserInfo.avatar"
              @error="errorHandler"
              :key="myUserInfo.avatar"
            >
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar> -->
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-circle-close">
                退出
              </el-dropdown-item>
            </el-dropdown-menu>
            {{ myUserInfo.user_name }}
            <i class="el-icon-caret-bottom"></i>
          </span>
        </el-dropdown>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Backstage",
  data() {
    return {
      icon: "el-icon-s-fold",
      isCollapse: false,
      activeIndex: "index",
      avatar: "",
    };
  },
  computed: {
    ...mapState("user", ["myUserInfo"]),
  },
  methods: {
    ...mapActions("user", { getMyUserinfo: "getMyUserinfo" }),
    ...mapMutations("login", {
      CHANGEADMINLOGINSTATUS: "CHANGEADMINLOGINSTATUS",
      GETRESOURCES: "GETRESOURCES",
    }),
    collapse() {
      if (this.icon === "el-icon-s-fold") {
        this.icon = "el-icon-s-unfold";
      } else {
        this.icon = "el-icon-s-fold";
      }
      this.isCollapse = !this.isCollapse;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push("/backstage/" + key);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    errorHandler() {
      return true;
    },
    handleCommand() {
      // this.$message("click on item " + command);
      this.logOut();
    },
    logOut() {
      this.$confirm("确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "成功!",
          });
          this.CHANGEADMINLOGINSTATUS(false);
          this.GETRESOURCES([]);
          localStorage.removeItem("myUserInfo");
          localStorage.removeItem("loginStatus");
          localStorage.removeItem("isAdminLogin");
          localStorage.removeItem("token");
          localStorage.removeItem("resources");
          // this.$router.push("/login");
          this.$router.push({ path: "/home" });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消",
          });
        });
    },
  },
  mounted() {
    this.getMyUserinfo();
    if (this.$route.path == "/backstage") {
      this.$router.push("/backstage/index");
    }
    // if (this.myUserInfo.avatar) {
    //   this.avatar = this.myUserInfo.avatar;
    // }
    if (this.$route.path == "/backstage/articlesManage") {
      this.activeIndex = "articlesManage";
    }
    if (this.$route.path == "/backstage/categoryManage") {
      this.activeIndex = "categoryManage";
    }
    if (this.$route.path == "/backstage/messagesManage") {
      this.activeIndex = "messagesManage";
    }
    if (this.$route.path == "/backstage/accountManage") {
      this.activeIndex = "accountManage";
    }
  },
};
</script>

<style lang="less" scoped>
.el-container {
  .el-aside {
    max-width: 15rem;
    height: 100vh;
    overflow: hidden;
    transition: 0.4s ease-in;
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 14.9375rem;
      min-height: 25rem;
    }
    .el-menu {
      height: 100vh;
      .el-menu-item {
        font-size: 1.125rem;
        // padding-left: 3.125rem !important;
        // padding: 0 auto !important;
        // font-weight: 550;
        // color: #797c81;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
  .isCollapse {
    transition: 0.3s ease-out;
    width: 4.0625rem !important;
  }
  /deep/.el-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 5rem !important;
    i {
      color: #797c81;
      cursor: pointer;
      font-size: 2.1875rem;
      transition: 0.3s;
    }
    .el-icon-s-unfold:hover {
      color: #409eff;
    }
    .el-icon-s-fold:hover {
      color: #409eff;
    }
    .left {
      display: flex;
      align-items: center;
      span {
        font-size: 1.5625rem;
        font-weight: 600;
        padding: 0 0.625rem;
        color: #4e4e4e;
      }
    }
    .right {
      display: flex;
      margin-right: 0.625rem;
      cursor: pointer;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        font-size: 1rem;
        .el-avatar {
          margin: 0 0.625rem;
        }
        i {
          font-size: 1.375rem;
        }
      }
    }
  }
  .main {
    height: 100vh;
    overflow: hidden;
  }
  .el-main {
    // background: rgb(243, 243, 243);
    overflow: hidden;
  }
}
</style>

